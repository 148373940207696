import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; 

function Header() {
  return (
    <header className="header">
      <Link to='/'>
      <div className="text">
        <span className="text-part1">INFINITE</span>
        <span className="text-part2">MUSIC.AI</span>
      </div>
      </Link>
    </header>
  );
}

export default Header;