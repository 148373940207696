import React from 'react';

function Background({ children }) {
  return (
    <div style={{ backgroundColor: '#0B1F32', minHeight: '100vh' }}>
      {children}
    </div>
  );
}

export default Background;