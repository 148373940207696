import React from 'react'
import "p5/lib/addons/p5.sound"
import * as p5 from "p5"

class MusicVisualization extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            song: null,
            isPlaying: false,
            hasBeenPlayed: false,
            isButtonClicked: false
        };
        this.myP5 = null;
        this.myRef = React.createRef()

        // Initialize class variables
        this.canvas = null;
        this.freqsound = null;
        this.col = null;
        this.blink = false;
        this.alpha = null;
        this.first_time = true;
        this.oldGaussians = null;
        this.energy = null;
        this.mappedAmplitude = null;
        this.amplitude = null;
        this.oscillationFrequency = null;
        this.g1 = null;
        this.g2 = null; 
        this.g3 = null; 
        this.g4 = null;
        this.minfreq = null;
        this.maxfreq = null;
        this.numGaussians = null;
        this.noiseMin = null;
        this.noiseMax = null;
        this.EMAweight = null;
        this.shape1 = null;
        this.shape4 = null;
        this.shape3 = null;
        this.initialgaussians = null;
        this.sigma = null;
        this.noiseAmplitude = null; 
        this.dynamicRange = null;
        this.jagged = false;
        this.increment = null;
        this.font = null;

        // Triangle coordinates for play button
        this.triangleX1 = null;
        this.triangleY1 = null;
        this.triangleX2 = null;
        this.triangleY2 = null;
        this.triangleX3 = null;
        this.triangleY3 = null;

        // rectangle coordinates for download button
        this.rectangleWidth = null;
        this.rectangleHeight = null;
        this.rectangleX = null;
        this.rectangleY = null;
    }

    // Initialize class methods
    loadSong = (songURL) => {
        const song = this.myP5.loadSound(songURL, () => {
            console.log('Song loaded', song);
            this.setState({ song, hasBeenPlayed: false }, () => { 
                this.state.song.onended(this.songEnded);
            });
        });
    }

    songEnded = () => {
        this.setState({ hasBeenPlayed: true });

    };

    drawTriangle = (p) => {
        const triangleHeight = 50;
        const triangleCenterX = p.width / 2;
        const triangleCenterY = p.height / 2 + 90;

        const x1 = triangleCenterX - triangleHeight / 2;
        const y1 = triangleCenterY + triangleHeight / 2;
        const x2 = triangleCenterX - triangleHeight / 2;
        const y2 = triangleCenterY - triangleHeight / 2;
        const x3 = triangleCenterX + triangleHeight / 2;
        const y3 = triangleCenterY;

        this.triangleX1 = x1;
        this.triangleY1 = y1;
        this.triangleX2 = x2;
        this.triangleY2 = y2;
        this.triangleX3 = x3;
        this.triangleY3 = y3;

        this.col = p.color(26, 121, 142); // teal
        if (this.blink){
            this.col.setAlpha(this.alpha);
            p.triangle(x1, y1, x2, y2, x3, y3);
        }
    }

    drawDownloadButton = (p) => {
        const rectangleWidth = 110;
        const rectangleHeight = 23;
        const rectangleCenterX = p.width / 2;
        const rectangleCenterY = (p.height / 2) + 160; 

        // calculate top left corner of the rectangle
        const x1 = rectangleCenterX - rectangleWidth / 2;
        const y1 = rectangleCenterY - rectangleHeight / 2;
        
        this.rectangleWidth = rectangleWidth;
        this.rectangleHeight = rectangleHeight;
        this.rectangleX = x1;
        this.rectangleY = y1;
        
        this.col = p.color(26, 121, 142); // teal
        if (this.blink){
            this.col.setAlpha(this.alpha);
            if (this.state.isButtonClicked) {
                p.fill('#18798E');
            } else {
                p.noFill();
            }
            p.push();
            // p.strokeWeight(2);
            p.rect(this.rectangleX, this.rectangleY, rectangleWidth, rectangleHeight);
            p.pop();
        }
                
        p.fill(26, 121, 142); 
        p.textFont(this.font);
        p.textSize(14);
        p.text("Download", rectangleCenterX - 29, rectangleCenterY + 5);
    }

    handleClick = (p) => {
        const mouseX = p.mouseX;
        const mouseY = p.mouseY;

        // Triangle detection
        const { triangleX1: x1, triangleY1: y1, triangleX2: x2, triangleY2: y2, triangleX3: x3, triangleY3: y3 } = this;
        const signOriginal = (x1*(y2 - y3) + x2*(y3 - y1) + x3*(y1 - y2)) < 0;
        const sign1 = (x1*(y2 - mouseY) + x2*(mouseY - y1) + mouseX*(y1 - y2)) < 0;
        const sign2 = (x1*(mouseY - y3) + mouseX*(y3 - y1) + x3*(y1 - mouseY)) < 0;
        const sign3 = (mouseX*(y2 - y3) + x2*(y3 - mouseY) + x3*(mouseY - y2)) < 0;

        if((sign1 === signOriginal) && (sign2 === signOriginal) && (sign3 === signOriginal)) {
            if (this.state.song && !this.state.isPlaying) {
                this.state.song.play();
                this.setState({ isPlaying: true });
            }
        }

        // Rectangle detection
        const { rectangleX: rx, rectangleY: ry, rectangleWidth: rw, rectangleHeight: rh } = this;

        if(mouseX >= rx && mouseX <= (rx + rw) && mouseY >= ry && mouseY <= (ry + rh)) {

            this.setState({ isButtonClicked: true });
            setTimeout(() => this.setState({ isButtonClicked: false }), 50);
            
            let a = document.createElement('a');
            a.href = this.props.songURL;
            a.download = this.props.songURL.split('/').pop();

            a.click();
        }
    }

    drawOffsetShapes = (p, shape, numCopies, maxXOffset, maxYOffset, strokeColor) => {
        p.Xstep = maxXOffset / numCopies;
        p.Ystep = maxYOffset / numCopies;

        if (p.frameCount / 15 > p.PI){
            this.first_time = false;
        }
        for (let i = 0; i < numCopies; i++) {
            if (!this.state.isPlaying && this.blink && this.first_time) {
                this.alpha = p.map(-p.cos(p.frameCount / 15), -1, 1, 0, 255);
            }
            else if (!this.state.isPlaying && this.blink && !this.first_time) {
                this.alpha = p.map(-p.cos(p.frameCount / 15), -1, 1, 100, 255);
            }
            else {
                this.alpha = p.map(i, 0, numCopies, 255, 100);
            }

            strokeColor.setAlpha(this.alpha);

            this.drawShape(p, shape.map(vertex => p.createVector(vertex.x + p.Xstep * i, vertex.y + p.Ystep * i)), strokeColor);
        }
    }

    drawShape = (p, shapeVertices, strokeColor) => {
        p.beginShape();
        p.noFill();
        p.stroke(strokeColor);
        for(let i = 0; i < shapeVertices.length; i++){
            p.vertex(shapeVertices[i].x, shapeVertices[i].y);
        }
        p.endShape();
    }

    initializeGaussians = (p, minfreq, maxfreq, numGaussians, noiseMin, noiseMax, EMAweight) => {
        // Use even numbers of gaussians for n<10
        let range = maxfreq - minfreq;
        let step = range / numGaussians;
        let gaussians = []; // Array to store Gaussian parameters

        // Initialize the Gaussian parameters
        for (let i = 0; i < numGaussians; i++) {
            gaussians[i] = {
                // Space the gaussians evenly across the width of the canvas, but start halfway to the left of the visible area and end halfway to the right of it
                x: (2 * p.windowWidth / numGaussians) * i - (p.windowWidth - (p.windowWidth / numGaussians)),
                // x:  (windowWidth / numGaussians) * i,
                y: p.windowHeight / 2,// + map(i, 0, numGaussians - 1, height / 4, -height / 4),
                amplitude: this.freqsound.getEnergy(minfreq + step * i),
                oscillationFrequency: p.random(noiseMin, noiseMax),
                EMAweight: EMAweight,
                range:range,
                minfreq:minfreq,
                meanEnergyOverTime: 0,
                updatesToMean: 1,
                energy: 0
            };
        }
        return gaussians;
    }

    calculateGaussians = (p, initialgaussians, sigma, noiseAmplitude, dynamicRange, jagged=false, undulate=false) => {

        let step = initialgaussians[0].range / initialgaussians.length;

        // Calculate the sum of Gaussian values for each x position
        let sumBaseline = p.height / 2; // Baseline value for the sum

        // this.freqsound.analyze();

        let shapeVertices = [];
        
        for (let i = 0; i < initialgaussians.length; i++) {
            initialgaussians[i].energy = this.freqsound.getEnergy(Math.exp(initialgaussians[i].minfreq + step * i), Math.exp(initialgaussians[i].minfreq + step * (i+1)));            
        }
        // Determine increment to use in for loop to calculate sum of Gaussian values
        this.increment = Math.floor(p.width / 100);
        // console.log(this.increment)
        // Prepopulate increment array to include remainder of p.width % increment

        // Calculate the sum of Gaussian values for each x position
        for (let x = 0; x < p.width; x+=this.increment) {
            let sum = 0;
            this.oldGaussians = initialgaussians;
            // Calculate the sum of Gaussian values at the current x
            for (let i = 0; i < initialgaussians.length; i++) {
                p.centerX = initialgaussians[i].x;
                p.centerY = initialgaussians[i].y;
                //amplitude is EMA of old and new amplitude
                // this.energy = this.freqsound.getEnergy(Math.exp(initialgaussians[i].minfreq + step * i), Math.exp(initialgaussians[i].minfreq + step * (i+1)));
                this.energy = initialgaussians[i].energy;

                initialgaussians[i].meanEnergyOverTime = initialgaussians[i].meanEnergyOverTime + (this.energy - initialgaussians[i].meanEnergyOverTime) / initialgaussians[i].updatesToMean;
                initialgaussians[i].updatesToMean += 1;

                // energy = energy / initialgaussians[i].meanEnergyOverTime;
                this.mappedAmplitude = p.map(this.energy, 0, 255, 0, dynamicRange);
                if(undulate || (jagged && this.mappedAmplitude > 0)){
                    this.mappedAmplitude += p.random(0, 200);
                }
                if (!this.state.isPlaying){
                    this.mappedAmplitude = 50;
                    noiseAmplitude  = .2;
                }
                this.amplitude = initialgaussians[i].EMAweight * initialgaussians[i].amplitude + (1 - initialgaussians[i].EMAweight) * this.mappedAmplitude;


                initialgaussians[i].amplitude = this.amplitude;
                this.oscillationFrequency = initialgaussians[i].oscillationFrequency;            
                sum += this.amplitude * p.exp(-((x - p.centerX) ** 2) / (2 * sigma ** 2)) * p.sin(p.frameCount * this.oscillationFrequency*noiseAmplitude - p.centerY);

            }

            // Normalize the sum of Gaussian values
            sum /= initialgaussians.length;    
            sum += sumBaseline; // Add the baseline value to the sum
            
            //map the sum so that if there's no signal the lines are at the center, and if there's a lot of signal they're at the top or the bottom
            sum = p.map(sum, sumBaseline, sumBaseline + dynamicRange, p.height/2, p.height/2 + dynamicRange);

            shapeVertices.push(p.createVector(x, sum));
        }
        return shapeVertices;
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.myP5 = new p5(this.Sketch, this.myRef.current);
        if (this.props.songURL) {
            this.loadSong(this.props.songURL);
        }
    }

    componentDidUpdate(prevProps) {
        // console.log('componentDidUpdate, audioStatus: ', this.props.audioStatus)
        // console.log(this.props.audioStatus)
        if (this.props.audioStatus === 'loading') {
            if (this.state.song) {
                this.state.song.pause();
                this.setState({ song: null });
            }            
        }
        if (this.props.songURL !== prevProps.songURL) {
            // this.myP5.remove();
            // this.myP5 = new p5(this.Sketch, this.myRef.current);
            this.loadSong(this.props.songURL);
            console.log('URL CHANGED')
        }
    }

    componentWillUnmount() {
        console.log('componentWillUnmount')
        if (this.myP5) {
            // if (this.state.song) {
            //     this.state.song.pause();
            // }
            this.myP5.remove();
        }
    }

    Sketch = (p) => {
        
        // p.loadFont('SourceSans3.ttf', (font) => {
        //     this.font = font;
        //     });

        // Initial setup to create canvas and audio analyzers
        p.setup = () => {
            this.canvas = p.createCanvas(p.windowWidth, p.windowHeight/2);
            console.log("CANVAS BEING CREATED")

            // Set random seed based on current time
            p.randomSeed(new Date().getTime());

            this.freqsound = new p5.FFT();
            this.freqsound.analyze();
            
            // 50-80 for gutteral
            this.g1 = this.initializeGaussians(p, this.minfreq = Math.log(120), this.maxfreq = Math.log(140), this.numGaussians = 4, this.noiseMin = 0.01, this.noiseMax=0.03, this.EMAweight=0);            
            this.g2 = this.initializeGaussians(p, this.minfreq = Math.log(4000), this.maxfreq = Math.log(7000), this.numGaussians = 8, this.noiseMin = 0.1, this.noiseMax = 0.4, this.EMAweight = 0.6);
            this.g3 = this.initializeGaussians(p, this.minfreq = Math.log(500), this.maxfreq = Math.log(4000), this.numGaussians = 6, this.noiseMin = 0.1, this.noiseMax = 0.4, this.EMAweight = 0.9);
            this.g4 = this.initializeGaussians(p, this.minfreq = Math.log(500), this.maxfreq = Math.log(4000), this.numGaussians = 8, this.noiseMin = 0.1, this.noiseMax = 0.4, this.EMAweight = 0.9);
            

            p.touchEnded = () => {
                this.handleClick(p);
            }                
            
            p.frameRate(30)
        }
        
        p.draw = () => {
            p.background(11, 31, 50);
            
            this.freqsound.analyze();

            if (!this.state.song) {
                this.setState({ isPlaying: false });
                this.col = p.color(26, 121, 142); //teal
                this.shape3 = this.calculateGaussians(p, this.initialgaussians = this.g4, this.sigma = 80, this.noiseAmplitude = .1, this.dynamicRange = 2000);
                p.currentX = p.map(p.frameCount, 0, .25 * p.width, 0, this.shape3.length);
                              
                this.blink = false;

                this.drawOffsetShapes(p, this.shape3, 1, 0, 0, this.col);
            }

            if (this.state.song && !this.state.song.isPlaying()) {
                this.setState({ isPlaying: false });
                this.col = p.color(26, 121, 142); //teal
                this.shape3 = this.calculateGaussians(p, this.initialgaussians = this.g4, this.sigma = 80, this.noiseAmplitude = .1, this.dynamicRange = 2000);
                p.currentX = p.map(p.frameCount, 0, .25 * p.width, 0, this.shape3.length);

                this.blink = true;

                this.drawOffsetShapes(p, this.shape3, 1, 0, 0, this.col);
                
                this.drawTriangle(p);  

                // Draw download button
                // if (this.state.hasBeenPlayed) {
                //     this.drawDownloadButton(p);
                // }
            }

            else if (this.state.song && this.state.song.isPlaying()) {
                // was this.g2 -> this.g1; noiseAmplitude = 0.4 -> 0.25; dynamicRange = 1500 -> 600

                this.col = p.color(237, 32, 98); //fuscha
                this.shape4 = this.calculateGaussians(p, this.initialgaussians = this.g2, this.sigma = 100, this.noiseAmplitude = 0.4, this.dynamicRange = 1500, this.jagged = true);
                this.drawOffsetShapes(p, this.shape4, 5, 0, 15, this.col);

                this.col = p.color(246, 150, 32); //orange
                this.shape4 = this.calculateGaussians(p, this.initialgaussians = this.g2, this.sigma = 100, this.noiseAmplitude = 0.4, this.dynamicRange = 1500, this.jagged = true);
                this.drawOffsetShapes(p, this.shape4, 1, 0, 0, this.col);

                this.col = p.color(126, 130, 193); //purple
                this.shape1 = this.calculateGaussians(p, this.initialgaussians = this.g1, this.sigma = 120, this.noiseAmplitude = 0.2, this.dynamicRange = 500);
                this.drawOffsetShapes(p, this.shape1, 5, 0, 20, this.col);

                this.col = p.color(80, 178, 229); //blue
                this.shape1 = this.calculateGaussians(p, this.initialgaussians = this.g1, this.sigma = 120, this.noiseAmplitude = 0.2, this.dynamicRange = 500);
                this.drawOffsetShapes(p, this.shape1, 1, 0, 0, this.col);

                // inverse of the above
                this.col = p.color(126, 130, 193); //purple
                this.shape1 = this.calculateGaussians(p, this.initialgaussians = this.g1, this.sigma = 120, this.noiseAmplitude = 0.2, this.dynamicRange = -500);
                this.drawOffsetShapes(p, this.shape1, 5, 0, 20, this.col);

                this.col = p.color(80, 178, 229); //blue
                this.shape1 = this.calculateGaussians(p, this.initialgaussians = this.g1, this.sigma = 120, this.noiseAmplitude = 0.2, this.dynamicRange = -500);
                this.drawOffsetShapes(p, this.shape1, 1, 0, 0, this.col);

                this.col = p.color(26, 121, 142); //teal
                this.shape3 = this.calculateGaussians(p, this.initialgaussians = this.g3, this.sigma = 80, this.noiseAmplitude = .1, this.dynamicRange = 1000, this.jagged = true);
                this.drawOffsetShapes(p, this.shape3, 5, 0, 10, this.col);

                this.col = p.color(26, 121, 142);
                this.shape3 = this.calculateGaussians(p, this.initialgaussians = this.g3, this.sigma = 80, this.noiseAmplitude = .1, this.dynamicRange = 1000);
                this.drawOffsetShapes(p, this.shape3, 1, 0, 0, this.col);
            }

            p.windowResized = function() {
                p.resizeCanvas(p.windowWidth, p.windowHeight/2);
                console.log("CANVAS BEING RESIZED")
            }
        }
    }

    render() {
      return (
        <div
          className="music-visualization"
          ref={this.myRef}
        />              
      )
    }
}

export default MusicVisualization
