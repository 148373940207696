import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AudioPlayer from './AudioPlayer';
import FAQ from './FAQ';
import Footer from './Footer';
import LoginPage from './LoginPage';
import Header from './Header';
import Background from './Background';
import HomePage from './HomePage';
import PrivacyNotice from './PrivacyNotice';
import TermsOfService from './TermsOfService';
import config from './config';


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);


  const handleLogin = async (username, token) => {
    try {
      console.log(config.API_URL);
      const response = await fetch(`${config.API_URL}/login?username=${username}&token=${token}`, {
        method: 'GET',
        credentials: 'include'
      });
      const data = await response.json();

      if (response.ok) {
        setIsLoggedIn(true);
      } else {
        alert(data.error);
      }
    } catch (err) {
      alert(err);
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <Background>
        <Header />
        <Footer />
        <Routes>
          <Route path="/login" element={isLoggedIn ? <Navigate to="/demo" /> : <LoginPage handleLogin={handleLogin} />} />
          <Route path="/demo" element={isLoggedIn ? <AudioPlayer handleLogout={handleLogout} /> : <Navigate to="/login" />} />
          {/* <Route path="/FAQ" element={<FAQ />} /> */}
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-notice" element={<PrivacyNotice />} />
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Background>
    </Router>
  );
}

export default App;
