/* 
Determine base URL for API based on current environment.
If running on localhost, use local development server URL. Otherwise, use relative URL
since backend and frontend are running on same server on Elastic Beanstalk 
*/

const API_URL = window.location.hostname === "localhost" ? "http://localhost:5000/api" : "api";

export default {
    API_URL
};
