import React from 'react';
import './TextPages.css';

function PrivacyNotice() {
    return (
        <div className="terms-container">
            <h1 className="terms-heading">Privacy Notice</h1>

            {/* Section 1 */}
            <div className="terms-section">
                <p>
                    <em>Last Revised on April 3, 2024</em>
                </p>
                <p>This Privacy Notice describes how Infinite Music AI, Inc. (“<strong>we</strong>”, “<strong>us</strong>”, “<strong>our</strong>”) collects, uses and discloses information about you when you use our website (infinitemusic.ai), applications, services, tools and features, or otherwise interact with us (collectively, the “<strong>Services</strong>”).  For the purposes of this Privacy Notice, “<strong>you</strong>” and “<strong>your</strong>” means you as the user of the Services.</p>
                <p>Please read this Privacy Notice carefully.  By using any of the Services, you agree to the collection, use, and disclosure of your information as described in this Privacy Notice.  If you do not agree to this Privacy Notice, please do not use or access the Services.</p>

                <ol>
                    <li><strong>CHANGES TO THIS PRIVACY NOTICE</strong></li>
                    <p>We may modify this Privacy Notice from time to time, in which case we will update the “Last Updated” date at the top of this Privacy Notice. If we make material changes to how we use or disclose information we collect, we will use reasonable efforts to notify you (such as by emailing you at the last email address you provided us, by posting notice of such changes on the Services, or by other means consistent with applicable law) and will take additional steps as required by applicable law.  If you do not agree to any updates to this Privacy Notice, please do not continue using or accessing the Services.</p>
                    <li><strong>COLLECTION AND USE OF INFORMATION</strong></li>
                    <p>When you use or access the Services, we collect certain categories of information about you from a variety of sources.</p>
                    <p><em>Information You Provide to Us</em></p>
                    <p>Some features of the Services may require you to directly provide certain information about yourself. You may elect not to provide this information, but doing so may prevent you from using or accessing these features. Information that you directly submit through our Services includes:</p>
                    <ul>
                        <li><strong>Basic contact details</strong>, such as name and email address. We use this information to create and maintain your account and provide the Services and to communicate with you (including to tell you about products or services that may be of interest to you).</li>
                        <li><strong>Account information</strong>, such as username, password, security questions that you select and the answers you provide. We use this information to provide the Services and to maintain and secure your account with us. If you choose to register an account, you are responsible for keeping your account credentials safe. We recommend you do not share your access details with anyone else. If you believe your account has been compromised, please contact us immediately.</li>
                        <li><strong>Payment information</strong>, such as credit or debit card information and billing address, which we collect using a third party payment processor. We use this information to process your payment and provide the Services.</li>
                        <li><strong>Applicant details</strong>, such as information included in your resume or CV, references, and job history. We use applicant details to process your application for employment and to evaluate your candidacy.</li>
                        <li><strong>User-generated content (“UGC”)</strong>, such as information you submit through discussion forums or similar social platforms on the Services. UGC also includes questions, prompts and other content (including music tracks, images, videos, and weblinks to additional content) submitted by you through the Services (“<strong>User Input</strong>”), which is then processed by the Services using artificial intelligence technology to generate and output new content (“<strong>User Output</strong>”). We may use User Input and User Output to improve the Services, including by training the artificial intelligence models that power the Services. We or others may store, display, reproduce, publish, or otherwise use UGC, and may or may not attribute it to you. Others may also have access to UGC and may have the ability to share it with third parties. If you choose to submit UGC to any public area of the Services, your UGC will be considered “public” and will be accessible by anyone, including us.</li>
                        <li><strong>Any other information you choose to include in communications with us</strong>, for example, when sending a message through the Services.</li>
                    </ul>
                    <br></br>
                    <p><em>Information Collected Automatically</em></p>
                    <p>We may also use cookies or other tracking technologies to automatically collect certain information about your interactions with the Services. We collect and use this information to tailor your experience with the Services and better understand user interactions with the Services. Such information includes:</p>
                    <ul>
                        <li><strong>Device information</strong>, such as device type, operating system, unique device identifier, and internet protocol (IP) address.</li>
                        <li><strong>Other information regarding your interaction with the Services</strong>, such as browser type, log data, date and time stamps.</li>
                    </ul>
                    <br></br>
                    <p>Most browsers accept cookies automatically, but you may be able to control the way in which your devices permit the use of cookies. If you so choose, you may block or delete certain of our cookies from your browser; however, blocking or deleting cookies may cause some of the Services, including any portal features and general functionality, to work incorrectly. Your browser settings may also allow you to transmit a “Do Not Track” signal when you visit various websites. Like many websites, our website is not designed to respond to “Do Not Track” signals received from browsers. To learn more about “Do Not Track” signals, you can visit <a href="http://www.allaboutdnt.com/">http://www.allaboutdnt.com</a>.</p>
                    <p>In addition to the specific uses described above, we may use any of the above information to provide you with the Services and to maintain our business relationship, including by enhancing the safety and security of our Services (e.g., troubleshooting, data analysis, testing, system maintenance, and reporting), providing customer support, sending service and other non-marketing communications, monitoring and analyzing trends, conducting internal research and development, complying with applicable legal obligations, enforcing any applicable terms of service, and protecting the Services, our rights, and the rights of our employees, users or other individuals.</p>
                    <p>Finally, we may deidentify or anonymize your information such that it cannot reasonably be used to infer information about you or otherwise be linked to you (or we may collect information that has already been deidentified/anonymized), and we may use such deidentified/anonymized information for any purpose.</p>
                    <li><strong>DISCLOSURE OF YOUR INFORMATION</strong></li>
                    <p>We may disclose your information for legitimate purposes subject to this Privacy Notice, including the following categories of third parties:</p>
                    <ul>
                        <li>Our affiliates or others within our corporate group.</li>
                        <li>Vendors or other service providers who help us provide the Services, including for system administration, cloud storage, security, customer relationship management, marketing communications, web analytics, payment networks, and payment processing.</li>
                        <li>Third parties to whom you request or direct us to disclose information, such as through your use of social media widgets or login integration.</li>
                        <li>Professional advisors, such as auditors, law firms, or accounting firms.</li>
                        <li>Third parties in connection with or anticipation of an asset sale, merger, bankruptcy, or other business transaction.</li>
                    </ul>
                    <br></br>
                    <p>We may also disclose your information as needed to comply with applicable law or any obligations thereunder or to cooperate with law enforcement, judicial orders, and regulatory inquiries, to enforce any applicable terms of service, and to ensure the safety and security of our business, employees, and users.</p>
                    <li><strong>SOCIAL FEATURES </strong></li>
                    <p>Certain features of the Services allow you to initiate interactions between the Services and third-party services or platforms, such as social networks (“<strong>Social Features</strong>”). Social Features include features that allow you to access our pages on third-party platforms, and from there “like” or “share” our content. Use of Social Features may allow a third party to collect and/or use your information. If you use Social Features, information you post or make accessible may be publicly displayed by the third-party service. Both we and the third party may have access to information about you and your use of both the Services and the third-party service. For more information, see the section below, <em>Third Party Websites and Links.</em></p>
                    <li><strong>THIRD PARTY WEBSITES AND LINKS</strong></li>
                    <p>We may provide links to third-party websites or platforms. If you follow links to sites or platforms that we do not control and are not affiliated with us, you should review the applicable privacy notice, policies and other terms.  We are not responsible for the privacy or security of, or information found on, these sites or platforms. Information you provide on public or semi-public venues, such as third-party social networking platforms, may also be viewable by other users of the Services and/or users of those third-party platforms without limitation as to its use. Our inclusion of such links does not, by itself, imply any endorsement of the content on such platforms or of their owners or operators.</p>
                    <li><strong>CHILDREN’S PRIVACY</strong></li>
                    <p>Our Services are not intended for children, and we do not seek or knowingly collect any personal information about children. If we become aware that we have unknowingly collected information about a child, in particular any child under 13 years of age, we will make commercially reasonable efforts to delete such information from our database. If you are the parent or guardian of a child under 13 years of age who has provided us with their personal information, you may contact us using the below information to request that it be deleted.</p>
                    <p>Any California residents under the age of 18 who have registered to use the Services and who have posted content or information on the Services can request that such information be removed from the Services by contacting us at the information provided below. Such request must state that they personally posted such content or information and detail where the content or information is posted. We will make reasonable good faith efforts to remove the post from prospective public view or anonymize it so the minor cannot be individually identified. This removal process cannot ensure complete or comprehensive removal. For instance, third parties may have republished the post and archived copies of it may be stored by search engines and others that we do not control.</p>
                    <li><strong>DATA SECURITY AND RETENTION</strong></li>
                    <p>Despite our reasonable efforts to protect your information, no security measures are impenetrable, and we cannot guarantee “perfect security.” Any information you send to us electronically, while using the Services or otherwise interacting with us, may not be secure while in transit. We recommend that you do not use unsecure channels to send us sensitive or confidential information.</p>
                    <p>We retain your information for as long as is reasonably necessary for the purposes specified in this Privacy Notice. When determining the length of time to retain your information, we consider various criteria, including whether we need the information to continue to provide you the Services, resolve a dispute, enforce our contractual agreements, prevent harm, promote safety, security and integrity, or protect ourselves, including our rights, property or products.</p>
                    <li><strong>HOW TO CONTACT US</strong></li>
                    <p>Should you have any questions about our privacy practices or this Privacy Notice, please email us at legal@infinitemusic.ai.</p>
                </ol>
            </div>
        </div >

    );
}

export default PrivacyNotice;
