import React from 'react';
import './TextPages.css';

function FAQ() {
    return (
        <div className="terms-container">
            <h1 className="terms-heading">FAQ</h1>

            <div class="terms-section faq-section">
                <div class="faq-item">
                    <strong>What is Infinitemusic?</strong>
                    <p>Infinitemusic is an AI music creation tool that you collaborate with to create original, fresh, high-quality music. Our goal is to make it really easy and fun to be musically creative. We’re musicians who’ve felt the joy of musical creation and we want everyone to experience that same joy! </p>
                </div>
                {/* <div class="faq-item">
                    <strong>Who is it for?</strong>
                    <p>Today it’s for bedroom producers, music enthusiasts, and anyone who wants to have fun creating original music tracks without the need for extensive technical skills. Whether you’re a beginner looking to explore new creative avenues or an experienced producer looking for inspiration, Infinitemusic can help.</p>
                </div> */}
                <div class="faq-item">
                    <strong>How does it work?</strong>
                    <p>It’s like a musical “choose your own adventure.” You start by asking us to make you a few short “seed” tracks in a given genre, or you can upload your own track. You can remix short seeds to try out different vibes, or you can extend any track into longer, different, variants. Each time, you’ll get back four different versions, and by choosing the ones you like, you’ll quickly put together a full track!</p>
                    <p>As you might expect, you can also prompt our model with genres/instruments/ideas that you want to hear in your music. We’re rapidly improving what the model can do — in response to what you tell us you want. So enjoy what it can do today, be patient when it acts silly, and stay pumped for updates!</p>
                </div>
                <div class="faq-item">
                    <strong>What kind of music can I make?</strong>
                    <p>Our AI is good at: <strong>house</strong>, <strong>drum n bass</strong>, <strong>metal</strong>, <strong>punk</strong>, <strong>rock</strong>, <strong>synthwave</strong>, <strong>trance</strong>, and <strong>ambient</strong> music. We're quickly improving our models to give you much more control and more genres. Tell us what genres or features you want next and we’ll get to work! Please shoot us an email at <a href="mailto:hello@infinitemusic.ai?subject=Feedback">hello@infinitemusic.ai</a>.</p>

                    {/* Most of the time it’s pretty great when you’re staying close to what it’s good at. Outside of these genres, don’t expect too much — for now.  */}
                </div>
                {/* <div class="faq-item">
                    <strong>Is it free?</strong>
                    <p>Yes!</p>
                </div> */}
                <div class="faq-item">
                    <strong>How can I get started with Infinitemusic?</strong>
                    <p>Click <a href="https://forms.gle/sc9gvBJUE9pPQJys8" target="_blank">here </a>
                        to request access to the private beta.</p>
                </div>
                <div class="faq-item">
                    <strong>What can I do with my tracks once I make them?</strong>
                    <p>Whatever you want! Share them with your friends on social media, use them as background music for your videos or podcasts, listen to them while you’re on a run, or perform them live at your next gig!</p>
                </div>
                {/* <div class="faq-item">
                    <strong>How is Infinitemusic different from other music AI companies?</strong>
                    <p>We’re putting you at the center of creation. This isn’t about getting a ready-made track in response to a prompt. It’s about crafting the music, piece by piece, in the way that you want it. It’s about having fun while you do that, about feeling the joy that comes with being creative. We know we’re not giving you the full experience of creativity just yet — but that’s our north star and we hope to get you there soon.</p>
                </div> */}
                {/* <div class="faq-item">
                    <strong>Does Infinitemusic want feedback?</strong>
                    <p>Absolutely! That’s why we’re launching our experimental beta. We have some exciting ideas about what the future of creation looks like, but we want to make sure we're building what's most exciting to you. Please reach out at <a href="mailto:hello@infinitemusic.ai?subject=Feedback">hello@infinitemusic.ai </a> and let us know what you think!</p>
                </div> */}
                {/* <div class="faq-item">
                    <strong>Is there an app?</strong>
                    <p>Yes — our Discord server works on the Discord mobile app, as well.</p>
                </div>
                <div class="faq-item">
                    <strong>Is there a community forum?</strong>
                    <p>Yes — our Discord server is where our community lives. Check out what other people are making, get feedback on your work, collaborate, and make friends.</p>
                </div> */}
                {/* <div class="faq-item">
                    <strong>What are the current limitations of the technology?</strong>
                    <p>Our tech is in an early, experimental phase. As such, it doesn’t sound great in many genres, can’t follow your prompts super well, and sometimes makes choices that are musically… interesting. Most of the time it’s pretty great when you’re staying close to what it’s good at. Stay tuned for updates — it’ll be a lot better soon.</p>
                </div> */}
                <div class="faq-item">
                    <strong>What are Inifnitemusic’s core values and mission?</strong>
                    <p>We believe that the future of music creation should be one where all artists — established, emerging, and hobbyist — can thrive and succeed. While the specific features and initiatives are still under development, our commitment is to work closely with the artist community to understand their needs and challenges, and to build tools and resources that help them navigate this new era of AI-powered music creation.</p>
                    <p>Crucially, we are committed to ensuring that artists retain full control and ownership over their creative work, and that they're fairly compensated for their contributions.</p>
                    <p>While we can't share all the details just yet, we want artists to know that they are at the heart of everything we do at Infinitemusic, and that we're dedicated to being a supportive partner in their creative journey. Stay tuned for more specifics in the coming months!</p>
                </div>
                <div class="faq-item">
                    <strong>Are you hiring?</strong>
                    <p>Yes. If you’re talented and excited about building the future of music creation with us, please reach out at <a href="mailto:hello@infinitemusic.ai?subject=Hiring">hello@infinitemusic.ai. </a></p>
                </div>
                {/* <p>We're thrilled to be sharing Infinitemusic with the world, and we can't wait to see what you create with it! If you have any other questions or feedback, please don't hesitate to reach out — we're always happy to chat.</p> */}

            </div>
        </div>

    );
}

export default FAQ;