import React, { useState } from 'react';
import './LoginPage.css';
import config from './config';

const LoginPage = ({ handleLogin }) => {
  const [username, setUsername] = useState('');
  const [token, setToken] = useState('');
  const [usernameValid, setUsernameValid] = useState(false);

  const handleUsernameSubmit = async (event) => {
    if (event.key !== 'Enter') return;
    event.preventDefault();
  
    try {      
      console.log(config.API_URL);
      const response = await fetch(`${config.API_URL}/check_user?username=${username}`, {
        method: 'GET',
        credentials: 'include' 
      });
      const data = await response.json(); 

      if (!response.ok) {
        alert(data.error);
      } else {
        setUsernameValid(true);
      }
    } catch (err) {
      alert(err);
    }
  };

  const handleTokenSubmit = async (event) => {
    if (event.key !== 'Enter') return;
    event.preventDefault();

    await handleLogin(username, token);
  };

  return (
    <div className="Login">
      <div className="login-form">
        <label className="login-label">
          {usernameValid ? 'Password' : 'Username'}:
        </label>
        <input 
          className="login-input" 
          type="text" 
          value={usernameValid ? token : username} 
          onChange={e => usernameValid ? setToken(e.target.value) : setUsername(e.target.value)} 
          onKeyDown={usernameValid ? handleTokenSubmit : handleUsernameSubmit} 
          autoFocus
          required 
        />
      </div>
    </div>
  );
};

export default LoginPage;
