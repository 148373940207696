import React, { useState, useEffect, useRef } from 'react';
import './AudioPlayer.css';
import { parseFloat } from 'core-js';
import MusicVisualization from './MusicVisualization';
import config from './config';


const AudioPlayer = ({ handleLogout }) => {
  const [text, setText] = useState('');
  const [index, setIndex] = useState(0);
  const [aiIndex, setAiIndex] = useState(0);
  const message = "What do you want to listen to? Try asking for something like \"energetic trap music\" or \"drum n bass with techno overtones\". ";
  const [aiMessage, setAiMessage] = useState(''); 
  const [textColor, setTextColor] = useState('#18798E');
  const [audioURL, setAudioURL] = useState(null);
  const textRef = useRef(null);
  const aiTextRef = useRef(null);
  const audioPlayerRef = useRef(null);
  const [audioStatus, setAudioStatus] = useState('idle'); // idle, loading, ready, error
  const [isTyping, setIsTyping] = useState(true);
  const [internalError, setInternalError] = useState(null); // eslint-disable-line
  const [modelBackendError, setModelBackendError] = useState(null);
  const [currentErrorMessage, setCurrentErrorMessage] = useState('');
  

  // Prints out initial AI message letter by letter
  useEffect(() => {
    if (index < message.length) {
      setIsTyping(true);
      const typingInterval = setInterval(() => {
        setText((prevText) => prevText + message[index]);
        setIndex((prevIndex) => prevIndex + 1);
      }, 20);

      return () => {
        clearInterval(typingInterval);
      };
    }
    else {
      setIsTyping(false);
    }
  }, [index, message]);

  // Types out the different AI error messages 
  useEffect(() => {
    if (aiMessage !== '' && aiIndex < currentErrorMessage.length) {
      const typingInterval = setInterval(() => {
        setAiMessage((prevText) => prevText + currentErrorMessage[aiIndex]);
        setAiIndex((prevIndex) => prevIndex + 1);
      }, 20);
      return () => {
        clearInterval(typingInterval);
      };
    }
  }, [aiIndex, currentErrorMessage, aiMessage]);

  // Makes user textarea scale with length of user input string
  useEffect(() => {
    const adjustHeight = () => {
        textRef.current.style.height = '1.5em'; 
        let scrollHeight = textRef.current.scrollHeight;
        const lineHeight = parseFloat(window.getComputedStyle(textRef.current).lineHeight);
        const maxHeight = lineHeight * 4;
        if(scrollHeight > maxHeight) {
            scrollHeight = maxHeight;
        }
        textRef.current.style.height = `${scrollHeight}px`;
    }

    // Run it initially
    adjustHeight();
  
    // Add event listener for window resize
    window.addEventListener('resize', adjustHeight);
  
    // Clean up event listener on unmount
    return () => window.removeEventListener('resize', adjustHeight);
}, [text]); // Re-run effect when `text` changes

  useEffect(() => {
    const adjustHeight = () => {
        aiTextRef.current.style.height = '1.5em'; 
        let scrollHeight = aiTextRef.current.scrollHeight;
        const lineHeight = parseFloat(window.getComputedStyle(aiTextRef.current).lineHeight);
        const maxHeight = lineHeight * 4;
        if(scrollHeight > maxHeight) {
            scrollHeight = maxHeight;
        }
        aiTextRef.current.style.height = `${scrollHeight}px`;
    }

    // Run it initially
    adjustHeight();
  
    // Add event listener for window resize
    window.addEventListener('resize', adjustHeight);
  
    // Clean up event listener on unmount
    return () => window.removeEventListener('resize', adjustHeight);
}, [aiMessage]); // Re-run effect when `text` changes


  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const fetchAudio = async () => {        
    try {      
      console.log(config.API_URL);

      await sleep(1500);  // 1.5-second delay

      const encodedText = encodeURIComponent(text);  // Encode text in order to gracefully handle special characters (e.g., &)
      const response = await fetch(`${config.API_URL}/get_audio?text=${encodedText}`, { credentials: 'include' });
      if (response.status === 401) {
        handleLogout();
        return;
      }
      
      const data = await response.json();     
      if (data.backend_model_error) {        
        setModelBackendError(data.backend_model_error);        
        setCurrentErrorMessage(data.backend_model_error);
        setAudioStatus('error');      
        return;
      }

      setAudioURL(data.url);
      setAudioStatus('ready');
      console.log(audioStatus);
    } catch (err) {
      // Handle other errors
      setInternalError(err.message);
      setAudioStatus('error');
    }    
  };

  // Handle side effects when audioStatus is 'error'
  useEffect(() => {
    if (audioStatus === 'error' && modelBackendError && currentErrorMessage) {
      console.log(modelBackendError);              
        setAiMessage(currentErrorMessage[0]); 
        setAiIndex(1);
      }          
  }, [audioStatus, modelBackendError]);

  // Debugging useEffect hook to keep track currentErrorMessage value
  useEffect(() => {
  console.log(currentErrorMessage);
}, [currentErrorMessage]);

  const handleKeyDown = (event) => {
    if (!isTyping) {
      if (event.key === 'Enter') {         
        event.preventDefault();
        setTextColor('#6690A1');
        audioPlayerRef.current.focus();
        setAudioStatus('loading');    
        if (text.trim() !== '') {
          fetchAudio();
        }
      }     

      if (text.trim() === message.trim()) {
        setText(event.target.value.replace(message, ''));
      }
    }
  };

  const handleChange = (e) => {
    if (!isTyping) {
      if (textColor !== '#E9FFFE') {
        setTextColor('#E9FFFE');
      }
      if (text.trim() === message.trim()) {
        setText(e.target.value.replace(message, ''));
      } else {
        setText(e.target.value);
      }
    }
  };

  return (
    <div className="audio-player" 
      ref={audioPlayerRef}
      tabIndex="0" 
      onKeyDown={(event) => {
        if (/^[a-zA-Z]$/.test(event.key) && document.activeElement !== textRef.current) {
          setText(''); 
          setAiMessage(''); 
          textRef.current.focus();
        }
      }}
    >
      <textarea 
        ref={textRef}
        className="audio-input" 
        style={{color: textColor}}
        value={text} 
        onChange={(e) => {handleChange(e)}} 
        onKeyDown={handleKeyDown} 
        onClick={() => {setText(''); setAiMessage('');}}
        autoFocus
      />
      <textarea 
        ref={aiTextRef}
        className="ai-input" 
        value={aiMessage} 
        onClick={() => {setText(''); setAiMessage(''); textRef.current.focus()}}
        readOnly
      />
      {(audioStatus !== 'idle') && <MusicVisualization songURL={audioURL} audioStatus={audioStatus}/>}
    </div>
  );
};

export default AudioPlayer;
